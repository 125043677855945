import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://ca02b14987f94b23f87372786db3ac34@o4506597083185152.ingest.sentry.io/4506597206654976',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/app\.klang\.ai\/api/,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    release: require('../package.json').version,
  });
}

import('./App').then(({ default: App }) => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<App />);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
