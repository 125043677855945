import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import en from './i18n/en/translation.json';
import sv from './i18n/sv/translation.json';

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
};

i18n.use(detector).use(initReactI18next).init({
  resources,
  fallbackLng: 'sv',
});

export default i18n;
